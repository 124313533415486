import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    products: [],  // Store the fetched About Us data
    productDetails: null,
    shippinginfo: null,
    filters: [],
    loading: false,     // For loading state
    error: null         // For any errors during the request
  };


export const getProduct = createAsyncThunk(
    'auth/getProduct',
    async (requestData, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/product_search', requestData);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getProductFilter = createAsyncThunk(
    'auth/getProductFilter',
    async (filters, { rejectWithValue }) => {
      try {
        const response = await axios.get('https://mattisirvioartgallery.com/admin/api/product_filter',filters);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getProductDetails = createAsyncThunk(
    'auth/getProductDetails',
    async (slug, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/product_id/'+slug);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        }
    },
    extraReducers: (builder) => {
            builder
              .addCase(getProduct.pending, (state) => {
                state.loading = true;
              })
              .addCase(getProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.products  = action.payload.data.result;
              })
              .addCase(getProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(getProductFilter.pending, (state) => {
                state.loading = true;
              })
              .addCase(getProductFilter.fulfilled, (state, action) => {
                state.loading = false;
                state.filters  = action.payload.data.result;
              })
              .addCase(getProductFilter.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(getProductDetails.pending, (state) => {
                state.loading = true;
              })
              .addCase(getProductDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.productDetails  = action.payload.data.result;
                state.shippinginfo = action.payload.data.shipping.page_description;
              })
              .addCase(getProductDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              });
             
          },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;
