import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton, PinterestShareButton, EmailShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, PinterestIcon, EmailIcon } from 'react-share';

const ExhibitionPost = ({exhibitionDetails,slug}) => {

const productUrl = `https://www.mattisirvioartgalleria.com/exhibitions/${slug}`;
const productTitle = exhibitionDetails.exhibition_title;
const productDescription = exhibitionDetails.exhibition_short_description;
const productImage = exhibitionDetails.exhibition_image;

const  encodedText = exhibitionDetails.exhibition_full_description;
  const fromdate = new Date(exhibitionDetails.exhibition_start_date);
  const fromformattedDate = fromdate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const todate = new Date(exhibitionDetails.exhibition_end_date);
  const toformattedDate = todate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  return (
    <Fragment>
      <div className="blog-details-top">
        
        <div className="blog-details-content">
          <div className="blog-meta-2">
            <ul>
              <li>{fromformattedDate} To {toformattedDate}</li>
            </ul>
          </div>
          
          <h3>{exhibitionDetails.exhibition_title}</h3>
          <div className="blog-details-img">
          <img
            alt=""
            src={exhibitionDetails.exhibition_image}
          />
        </div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: encodedText }} />
        <div className="share-buttons pro-details-social">
                <FacebookShareButton 
                  url={productUrl} 
                  quote={productTitle} 
                  hashtag="#ProductShare"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
        
                <TwitterShareButton 
                  url={productUrl} 
                  title={productTitle}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
        
                <PinterestShareButton 
                  url={productUrl} 
                  media={productImage} 
                  description={productDescription}
                >
                  <PinterestIcon size={32} round />
                </PinterestShareButton>
        
                <EmailShareButton 
                  subject={`Check out this product: ${productTitle}`} 
                  body={`I found this product: ${productTitle} - ${productUrl}`}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
        
        </div>
      </div>
    
    </Fragment>
  );
};

export default ExhibitionPost;
