import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  
  return (
    <div
      className={clsx(sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`)}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {t("home")}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/about"}>
              {t("About Us")}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/arts"}>
              {t("Artworks")}
            </Link>
          </li>
          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {t("Services")}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
            <li>
                <Link to={process.env.PUBLIC_URL + "/services/arts"}>
                  {t("Artwork")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/services/exhibition"}>
                  {t("Exhibition")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/services/solo-exhibition"}>
                  {t("Solo Exhibition")}
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/artist"}>
              {t("Artist Profile")}
            </Link>
          </li> */}
          <li>
            <Link to={process.env.PUBLIC_URL + "/exhibitions"}>
              {t("Exhibitions")}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/press-release"}>
              {t("Press")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
