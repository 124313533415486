import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { useSelector, useDispatch } from "react-redux";
import { getSettingData, postContactForm } from "../../store/slices/general-slice";
const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const { settings, loading, error } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(getSettingData());
    }, [dispatch]);
  
  if (!settings) {
    // You can display a loading spinner or message here
    return <div>Loading...</div>;
  }
  console.log(settings);
  return (
    <footer className={clsx("footer-area", backgroundColorClass, spaceTopClass, spaceBottomClass, extraFooterClass, spaceLeftClass, spaceRightClass )}>
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-md-3"
            }`}
          >
            <FooterCopyright
              name={settings.company_name}
              footerLogo={settings.company_fav}
              spaceBottomClass="mb-30"
            />
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-md-2"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>ABOUT US</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About us</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-md-2"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-50"
              }`}
            >
              <div className="footer-title">
                <h3>Services</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                  <Link to={process.env.PUBLIC_URL + "/services/arts"}>ArtWork</Link>
                  </li>
                  <li>
                  <Link to={process.env.PUBLIC_URL + "/services/exhibition"}>Exhibition</Link>
                  </li>
                  <li>
                  <Link to={process.env.PUBLIC_URL + "/services/solo-exhibition"}>ArtWork</Link>
                  </li>
                </ul>
              </div>
            </div>  
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-4" : "col-md-2"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-75"
              }`}
            >
              <div className="footer-title">
                <h3>FOLLOW US</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <a
                      href={settings.facbook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href={settings.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href={settings.instrgram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                       href={settings.google_plus}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Plus
                    </a>
                  </li>
                  <li>
                    <a
                       href={settings.google_plus}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Whatsapp
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-3 col-sm-3"
            }`}
          >
           
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
