import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { v4 as uuidv4 } from 'uuid';

// Async Thunk to handle async logic for adding a product to the cart
export const addCart = createAsyncThunk(
  'cart/addCart',
  async (product, { rejectWithValue, dispatch }) => {
    try {
      const sessionId = localStorage.getItem('sessionId') || uuidv4();
      localStorage.setItem('sessionId', sessionId); // Store sessionId for future use
      const response = await axios.post('https://mattisirvioartgallery.com/admin/api/cart/add', {
        session_id : sessionId,
        product_id: product.id,
        quantity: 1
      });
      dispatch(getCart(sessionId));  // Fetch the updated cart

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCart = createAsyncThunk(
    'cart/getCart',
    async (sessionId, { rejectWithValue }) => {
      try {
        const response = await axios.get('https://mattisirvioartgallery.com/admin/api/cart/list/'+sessionId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const deleteCart = createAsyncThunk(
    'cart/deleteCart',
    async (requestData, { rejectWithValue, dispatch }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/cart/delete',requestData);
        const sessionId = localStorage.getItem('sessionId');
        dispatch(getCart(sessionId));  // Fetch the updated cart
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const deleteAllFromCart = createAsyncThunk(
    'cart/deleteAllFromCart',
    async (requestData, { rejectWithValue, dispatch }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/cart/delete_all',requestData);
        const sessionId = localStorage.getItem('sessionId');
        dispatch(getCart(sessionId));  // Fetch the updated cart
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: [],
    cartData: null,
    status: 'idle',
    loading : false,
    error: null,
  },
  reducers: {
    addToCart(state, action) {
      const product = action.payload;
      if (!product.variation) {
        const cartItem = state.cartItems.find(item => item.id === product.id);
        if (!cartItem) {
          state.cartItems.push({
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuidv4(),
          });
        } else {
          state.cartItems = state.cartItems.map(item => {
            if (item.cartItemId === cartItem.cartItemId) {
              return {
                ...item,
                quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
              };
            }
            return item;
          });
        }
      } else {
        const cartItem = state.cartItems.find(
          item =>
            item.id === product.id &&
            product.selectedProductColor === item.selectedProductColor &&
            product.selectedProductSize === item.selectedProductSize &&
            (product.cartItemId ? product.cartItemId === item.cartItemId : true)
        );
        if (!cartItem) {
          state.cartItems.push({
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuidv4(),
          });
        } else {
          state.cartItems = state.cartItems.map(item => {
            if (item.cartItemId === cartItem.cartItemId) {
              return {
                ...item,
                quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize,
              };
            }
            return item;
          });
        }
      }

      cogoToast.success('Added To Cart', { position: 'bottom-left' });
    },
    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
      cogoToast.error('Removed From Cart', { position: 'bottom-left' });
    },
    decreaseQuantity(state, action) {
      const product = action.payload;
      if (product.quantity === 1) {
        state.cartItems = state.cartItems.filter(item => item.cartItemId !== product.cartItemId);
        cogoToast.error('Removed From Cart', { position: 'bottom-left' });
      } else {
        state.cartItems = state.cartItems.map(item =>
          item.cartItemId === product.cartItemId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        cogoToast.warn('Item Decremented From Cart', { position: 'bottom-left' });
      }
    },
    olddeleteAllFromCart(state) {
      state.cartItems = [];
    },
    getProductCartQuantity() {
      return 9;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addCart.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(addCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        cogoToast.success('Added To Cart', { position: 'bottom-left' });
      })
      .addCase(addCart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.loading = false;
        cogoToast.error('Failed to Add To Cart', { position: 'bottom-left' });
      })
      .addCase(getCart.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.cartItems = action.payload.data.result;
        state.cartData = action.payload.data;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteCart.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        cogoToast.success('Item Removed From Cart', { position: 'bottom-left' });
      })
      .addCase(deleteCart.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.payload;
        cogoToast.error('Failed to Remove Item From Cart', { position: 'bottom-left' });

      });
  },
});

export const { addToCart, deleteFromCart, decreaseQuantity, getProductCartQuantity } = cartSlice.actions;
export default cartSlice.reducer;
