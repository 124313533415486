import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import SEO from "../../components/seo";
import { getDiscountPrice } from "../../helpers/product";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { addToCart, deleteCart, deleteAllFromCart } from "../../store/slices/cart-slice";
import { cartItemStock } from "../../helpers/product";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const Confirmation = () => {
  let cartTotalPrice = 0;
  const { orderId } = useParams();
  const sessionId = localStorage.getItem('sessionId') || uuidv4();
  const [quantityCount] = useState(1);
  const dispatch = useDispatch();
  let { pathname } = useLocation();

  const currency = useSelector((state) => state.currency);
  const { cartData, cartItems } = useSelector((state) => state.cart);
  
  const [orderDetails, setOrderDetails] = useState(null); // Store order data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch order details based on orderId
  useEffect(() => {
    if (orderId) {
      setLoading(true);
      axios
        .get(`https://mattisirvioartgallery.com/admin/api/order_details/${orderId}`)  // Adjust this endpoint as per your API
        .then((response) => {
          setOrderDetails(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError("Error fetching order details.");
          setLoading(false);
        });
    }
  }, [orderId]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Buy Paintings Online | Matti Sirvio Galleria"
        description="Buy Wall Art and Wall Paintings Online in oman. Matti Sirvio Galleri  gives a distinctive range of wall-placing paintings, ornamental wall plates, and greater on the first-class charges."
      />

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Confirmation", path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="cart-main-area pt-90 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                   

                    {/* Display order details if available */}
                    {loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p>{error}</p>
                    ) : orderDetails ? (
                      <div>
                        <i className="pe-7s-check"></i>
                        <h2>Thank You. Your Order has been received!</h2>
                        <p>Your order has been confirmed. Conformation mail will be send your register email..</p>
                        <div className="booking-details">
      <div className="h5 mb-4">Booking Details</div>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-6 mb-md-0 mb-2">
          <div className="booking-id">
            <div className="th">booking no</div>
            <div className="td">{orderDetails.data.result.order_no}</div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-6 mb-md-0 mb-2">
          <div className="booking-date">
            <div className="th">Booking date</div>
            <div className="td">{orderDetails.data.result.booking_date}</div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-6">
          <div className="booking-amount">
            <div className="th">Total Amount</div>
            <div className="td">{orderDetails.data.result.total_amount}</div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-6">
          <div className="Payment-method">
            <div className="th">Payment Method</div>
            <div className="td">{orderDetails.data.result.payment_type}</div>
          </div>
        </div>
      </div>
    </div>
    <br></br>
    <br></br>
    <div className="order-summery">
      <div className="title h5 mb-4"><b>Order Details</b></div>
      <div className="row">
        <div className="col-lg-6">
        
      {orderDetails.data.result.products.map((product, index) => (
        <div className="items">
        <div className="order-item" key={index}>
          <div className="row align-items-center">
            <div className="col-lg-2 col-sm-2 col-3 text-center">
              <img
                src={product.product_image} // Assuming imageUrl is part of the product object
                alt={product.product_name}
                style={{ width: '80px', height: '80px' }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-7 col-7">
              <h6 className="art-name">{product.product_name}</h6>
              <span>{product.product_size}, {product.product_medium}, {product.product_orientation}</span> {/* Adjust this based on the product structure */}
            </div>
            <div className="col-lg-4 col-sm-3 col-2">
              <div className="art-price">{product.product_quantity} * {product.product_price}</div> {/* Adjust based on product structure */}
            </div>
          </div>
        </div>
        </div>
      ))}
    


        </div>
        <div className="col-lg-5">
          <div className="price-info">
            <div className="h5">Price Info</div>
            <div className="sub-total py-2">
              <span className="th">Sub Total</span>
              <span className="td">{orderDetails.data.result.sub_total}</span>
            </div>
            <div className="shipping-fee py-2">
              <span className="th">Shipping Fee</span>
              <span className="td">{orderDetails.data.result.delivery_charge}</span>
            </div>
            <div className="tax-fee py-2">
              <span className="th">Tax Fee</span>
              <span className="td">{orderDetails.data.result.tax}</span>
            </div>
            <div className="total py-3">
              <span className="th">Total</span>
              <span className="td">{orderDetails.data.result.total_amount}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


                      </div>
                    ) : null}

                  </div>
                  <div className="item-empty-area__text">
                    <Link to={process.env.PUBLIC_URL + "/arts"}>
                      Shop Now
                    </Link>
                  </div>
                </div>
              </div>
           
              
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Confirmation;
