import { Fragment,useEffect } from "react";
import { useParams, useLocation } from "react-router-dom"; 
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ExhibitionSidebar from "../../wrappers/blog/ExhibitionSidebar";
import BlogComment from "../../wrappers/blog/BlogComment";
import ExhibitionPost from "../../wrappers/blog/ExhibitionPost";
import { useSelector, useDispatch } from "react-redux";
import { getExhibitions,getExhibitionDetails } from "../../store/slices/exhibition-slice";

const ExhibitionDetailsStandard = () => {
  let { pathname } = useLocation();
   let { slug } = useParams();
  const dispatch = useDispatch();
  const { exhibitionDetails, exhibitions } = useSelector((state) => state.exhibition);
   useEffect(() => {
              dispatch(getExhibitions());
      }, [dispatch]);
      
     useEffect(() => {
                if(slug){
                    dispatch(getExhibitionDetails(slug)); 
                }
                
        }, [dispatch,slug]);
  if (!exhibitionDetails) {
    // You can display a loading spinner or message here
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      {exhibitionDetails && <SEO
        titleTemplate={exhibitionDetails.exhibition_title}
        description={exhibitionDetails.exhibition_short_description}
      />}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {exhibitionDetails && <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "Exhibitions", path: process.env.PUBLIC_URL + '/exhibitions' },
            {label: exhibitionDetails.exhibition_title, path: process.env.PUBLIC_URL + pathname }
          ]} 
        />}
        <div className="blog-area pt-100 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="blog-details-wrapper ml-20">
                  {/* blog post */}
                  {exhibitionDetails && <ExhibitionPost exhibitionDetails={exhibitionDetails} slug={slug} />}
                </div>
              </div>
              <div className="col-lg-3">
                {/* blog sidebar */}
                <ExhibitionSidebar exhibitions={exhibitions} id={exhibitionDetails.id} />
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ExhibitionDetailsStandard;
