import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch,useSelector } from 'react-redux';  // assuming you're using Redux for dispatch
import { applyExhibitions } from "../../store/slices/exhibition-slice";
import cogoToast from 'cogo-toast';
const ExhibitionSidebar = ({exhibitions,id}) => {
    
 const { message } = useSelector((state) => state.exhibition);
  // State to control modal visibility and form data
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    exhibition_id : id,
    artist_name: '',
    artist_email: '',
    artist_mobile: '',
    artist_description: '',
    painting_upload: null,
    painting_name: '',
    upload_portfolio: null,
  });

  const dispatch = useDispatch(); // Create dispatch instance

  // Function to open the modal
  const handleForm = () => {
    setModalShow(true);
  };

  // Function to close the modal
  const onCloseModal = () => {
    setModalShow(false);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') { 
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // for file inputs, use the file
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // for text inputs, use the value
      }));
    }
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Dispatch the form data to redux
    dispatch(applyExhibitions(formData))
        .then((response) => { 
          if (response?.payload?.data?.message != '') {
            // Navigate to the 'my-account' page with state
            cogoToast.success("Exhibition applied successfully.", {position: "bottom-left"});
          } else {
            cogoToast.error("Failed to update Apply Exhibition.", {position: "bottom-left"});
          }
        })
        .catch((error) => {
          cogoToast.error("Error while Apply Exhibition :"+error, {position: "bottom-left"});
        });

    // Close modal after submission
    onCloseModal();
  };

  return (
    <div className="sidebar-style">
      <div className="sidebar-widget">
        <div className="myaccount-info-wrapper pro-sidebar-search mb-55 mt-25">
          <div className="billing-back-btn">
            <div className="item-empty-area__text"> <br /> 
              <a style={{ padding: '9px 61px' }} onClick={handleForm} href="javascript:void(0);">Apply Exhibition</a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="sidebar-widget mt-35">
        <h4 className="pro-sidebar-title">Upcoming Exhibition</h4>
        <div className="sidebar-widget-list sidebar-widget-list--blog mt-20">
          <ul>
            {exhibitions.map((exhibition, key) => (
              <li key={key}>
                <div className="sidebar-widget-list-left">
                  <Link to={process.env.PUBLIC_URL + "/exhibitions/"+exhibition.exhibition_slug}>
                    {exhibition.exhibition_title}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Modal show={modalShow} onHide={onCloseModal} className="product-quickview-modal-wrapper">
        <Modal.Header closeButton><h3>Apply Exhibition</h3></Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label htmlFor="artistname">Artist Name <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="artistname"
                    name="artist_name"
                    value={formData.artist_name}
                    onChange={handleInputChange}
                    placeholder="Artist Name"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label htmlFor="artistemail">Email <span className="text-danger">*</span></label>
                  <input
                    type="email"
                    className="form-control"
                    id="artistemail"
                    name="artist_email"
                    value={formData.artist_email}
                    onChange={handleInputChange}
                    placeholder="Artist Email"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label htmlFor="artistmobile">Artist Mobile <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="artistmobile"
                    name="artist_mobile"
                    value={formData.artist_mobile}
                    onChange={handleInputChange}
                    placeholder="Artist Mobile"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label htmlFor="artistdescription">Artist Description <span className="text-danger">*</span></label>
                <textarea
                  className="form-control"
                  id="artistdescription"
                  name="artist_description"
                  value={formData.artist_description}
                  onChange={handleInputChange}
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label htmlFor="paintingUpload">Painting Upload <span className="text-danger">*</span></label>
                <input
                  type="file"
                  className="form-control"
                  id="paintingUpload"
                  name="paintingUpload"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label htmlFor="paintingName">Painting Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="paintingName"
                  name="painting_name"
                  value={formData.painting_name}
                  onChange={handleInputChange}
                  placeholder="Painting Name"
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label htmlFor="portfolioUpload">Upload Portfolio (if any)</label>
                <input
                  type="file"
                  className="form-control"
                  id="portfolioUpload"
                  name="portfolioUpload"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onCloseModal}>Close</button>
              <button type="submit" className="btn btn-primary">Apply</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ExhibitionSidebar;
