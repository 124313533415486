import PropTypes from "prop-types";
import React, { Fragment,useEffect } from "react"; 
import { useParams, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProduct from "../../wrappers/product/RelatedProduct";
import { useSelector, useDispatch } from "react-redux";
import { artist,pressReleases } from "../../store/slices/exhibition-slice";
import { getProduct } from "../../store/slices/product-slice";
import { Link } from "react-router-dom";
import BlogFeaturedThreeSingle from "../../components/blog-featured/BlogFeaturedThreeSingle";
 
const PressRelease = () => {
      const dispatch = useDispatch();

      const { press_releases } = useSelector((state) => state.exhibition);
      useEffect(() => {  
            dispatch(pressReleases());
            }, [dispatch]);
      if (!press_releases) {
        // You can display a loading spinner or message here
        return <div>Loading...</div>;
      }
  return (
    <Fragment>
          <SEO
            titleTemplate="Press Release"
            description="Press Release"
          />
    
          <LayoutOne headerTop="visible">
            {/* breadcrumb */}
            <Breadcrumb 
              pages={[
                {label: "Home", path: process.env.PUBLIC_URL + "/" },
                {label: "Press Release", path: process.env.PUBLIC_URL + "/press-release" }
              ]} 
            />
    <div className="shop-area pt-10 pb-10">
      <div className="container">
        
        <div className="product-area pt-0">
      <div className="container">
        <div className="section-title text-center">
          <h2>Press Release</h2>
        </div>
        <div className="product-tab-list pt-30 pb-55  nav nav-pills" role="tablist">
           <div className="row">
                   {press_releases.map(singlePost => (
                     <div className="col-lg-4 col-sm-6" key={singlePost.article_title}>
                       <BlogFeaturedThreeSingle
                         singlePost={singlePost}
                       />
                     </div>
                   ))}
                 </div>
        </div>
      </div>
    </div>


     
      </div>
    </div>
    </LayoutOne>
    </Fragment>
  );
};

PressRelease.propTypes = {
};

export default PressRelease;
