import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// API call for login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post('https://mattisirvioartgallery.com/admin/api/user/login', credentials);
      return response.data; // Assume response contains user data and token
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (userData, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/user/register', userData);
        return response.data; // Assume response contains success message or user data
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const saveProfile = createAsyncThunk(
    'auth/saveProfile',
    async (userData, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/user/updateProfile', userData);
        return response.data; // Assume response contains success message or user data
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const savePassword = createAsyncThunk(
    'auth/savePassword',
    async (userData, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/user/updatePassword', userData);
        return response.data; // Assume response contains success message or user data
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.result;
        state.token = action.payload.data.result.user_token;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Register Cases
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message; // Success message after registration
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message after failed registration
      })
      // Save Profile Cases
      .addCase(saveProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveProfile.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data[0]?.result?.user_token) {
        state.user = action.payload.data[0].result;
        state.token = action.payload.data[0].result.user_token;
        }
      })
      .addCase(saveProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message after failed registration
      })
      // Save Password
      .addCase(savePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(savePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message; // Success message after registration
      })
      .addCase(savePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message after failed registration
      })
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
