import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDiscountPrice } from "../../../helpers/product";
import { deleteCart } from "../../../store/slices/cart-slice"
import { v4 as uuidv4 } from 'uuid';

const MenuCart = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const { cartData,cartItems } = useSelector((state) => state.cart);
  let cartTotalPrice = 0;
  const sessionId = localStorage.getItem('sessionId') || uuidv4();

  return (
    <div className="shopping-cart-content">
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              const finalProductPrice = (
                item.product_price
              );
              (cartTotalPrice += finalProductPrice * item.quantity);

              return (
                <li className="single-shopping-cart" key={item.product_id}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/arts/" + item.product_slug}>
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + item.feature_image}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/arts/" + item.product_slug}
                      >
                        {" "}
                        {item.product_name}{" "}
                      </Link>
                    </h4>
                    <h6>Qty: {item.quantity}</h6>
                    <span>
                    {item.product_price}
                    </span>
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => dispatch(deleteCart({product_id : item.product_id,session_id : sessionId}))}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
          <h4>
              Sub Total :{" "}
              <span className="shop-total">
               {cartData.sub_total}
              </span>
            </h4>
            <h4>
              Tax :{" "}
              <span className="shop-total">
               {cartData.tax_price}
              </span>
            </h4>
            <h4>
              Shipping Charge :{" "}
              <span className="shop-total">
               {cartData.shipping_price}
              </span>
            </h4>
            <h4>
              Total :{" "}
              <span className="shop-total">
               {cartData.total_price}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

export default MenuCart;
