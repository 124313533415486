import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ExhibitionDetailsStandard from "./pages/blog/ExhibitionDetailsStandard";
import ArtistInfo from "./wrappers/product/ArtistInfo";
import Artist from "./wrappers/product/Artist";
import PressRelease from "./wrappers/product/PressRelease";
import Exhibitions from "./wrappers/product/Exhibitions";
import ExhibitionsPage from "./pages/other/Exhibitions";
import ArtsPage from "./pages/other/ArtsPage";
import SoloExhibitionsPage from "./pages/other/SoloExhibitionsPage";
import Confirmation from "./pages/other/Confirmation";
//import ExhibitionDetailsStandard from "./src/pages/ExhibitionDetailsStandard";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const Artwork = lazy(() => import("./pages/shop/Artwork"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));


// blog pages
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFashion/>}
              />

              {/* Homepages */}
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion"}
                element={<HomeFashion/>}
              />
             

              {/* Shop pages */}
              <Route
                path={process.env.PUBLIC_URL + "/arts"}
                element={<Artwork/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/press-release"}
                element={<PressRelease/>}
              />


              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/arts/:slug"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/artist/:slug"}
                element={<ArtistInfo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/artist"}
                element={<Artist />}
              />
              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                element={<BlogNoSidebar/>}
              />
        
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                element={<BlogDetailsStandard/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/exhibitions/:slug"}
                element={<ExhibitionDetailsStandard/>}
              /> 

              <Route
                path={process.env.PUBLIC_URL + "/exhibitions"}
                element={<Exhibitions/>}
              /> 

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/services/arts"}
                element={<ArtsPage/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/services/exhibition"}
                element={<ExhibitionsPage />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/services/solo-exhibition"}
                element={<SoloExhibitionsPage/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                element={<MyAccount/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={<LoginRegister/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout/>}
              /> 

              <Route
                path={process.env.PUBLIC_URL + "/confirmation/:orderId"}
                element={<Confirmation/>}
              />

              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;