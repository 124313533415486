import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogFeaturedSingle = ({ singlePost }) => {

  console.log('exhibitions/' + singlePost.exhibition_slug);
  return (
    <div className="blog-wrap mb-30 scroll-zoom">
      <div className="blog-img">
        <Link to={'/exhibitions/' + singlePost.exhibition_slug}>
          <img src={singlePost.exhibition_image} alt="" />
        </Link>
      </div>
      <div className="">
        <div className="blog-content  pt-10 pb-10 ">
        <span className="exhibition-date">
            <Link to={'/exhibitions/' + singlePost.exhibition_slug}>
              {singlePost.exhibition_start_date} To {singlePost.exhibition_end_date}
            </Link>
          </span>
          <h2 className="exhibition-title">
            <Link to={'/exhibitions/' + singlePost.exhibition_slug}>
              {singlePost.exhibition_title}
            </Link>
          </h2>
    
        </div>
      </div>
    </div>
  );
};

BlogFeaturedSingle.propTypes = {
  singlePost: PropTypes.shape({})
};

export default BlogFeaturedSingle;
