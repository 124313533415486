import PropTypes from "prop-types";
import React, { Fragment,useEffect } from "react"; 
import { useParams, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProduct from "../../wrappers/product/RelatedProduct";
import { useSelector, useDispatch } from "react-redux";
import { artistDetails } from "../../store/slices/exhibition-slice";
import { getProduct } from "../../store/slices/product-slice";
import { Link } from "react-router-dom";

const ArtistInfo = () => {
      const dispatch = useDispatch();
      let { slug } = useParams();
      const { artistDetail } = useSelector((state) => state.exhibition);
      const { products } = useSelector((state) => state.product);
      useEffect(() => {  
          if (slug) { 
            dispatch(artistDetails(slug));
          }
            }, [dispatch]);

      useEffect(() => {  
              if (slug) { 
                dispatch(getProduct({artist_slug : slug}));
              }
                }, [dispatch]);

if (!artistDetail) {
  // You can display a loading spinner or message here
  return <div>Loading...</div>;
}
const  encodedText = artistDetail.description;

  return (
    <Fragment>
          <SEO
            titleTemplate={`${artistDetail.first_name} ${artistDetail.last_name}`}
            description={artistDetail.description}
          />
    
          <LayoutOne headerTop="visible">
            {/* breadcrumb */}
            <Breadcrumb 
              pages={[
                {label: "Home", path: process.env.PUBLIC_URL + "/" },
                {label: "Artist", path: process.env.PUBLIC_URL + "/artist" },
                {label: `${artistDetail.first_name} ${artistDetail.last_name}`, path: process.env.PUBLIC_URL +"/artist/" }
              ]} 
            />
    <div className="shop-area pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
          <div className="product-large-image-wrapper">
          <div className="single-image">
                  <img
                   src={artistDetail.profile_image} alt={`${artistDetail.first_name} ${artistDetail.last_name}`}
                    className="img-fluid"
                  />
                </div>
            </div>
          </div>
          <div className="col-md-8">
          <div className="product-details-content ml-70">
          <h2>{`${artistDetail.first_name} ${artistDetail.last_name}`}</h2>
                <div className="pro-details-list">
                <div dangerouslySetInnerHTML={{ __html: encodedText }} />
                    {artistDetail.location ? (
                        <div className="pro-details-meta">
                         <span><i className="fa fa-map-marker"></i></span>
                          <ul>
                                <li>
                                    {artistDetail.location}
                                </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}

                    {products.length > 0 ? (
                        <div className="pro-details-meta">
                         <span><i className="fa fa-file"></i></span>
                          <ul>
                                <li>
                                Artworks -  {products.length}
                                </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
            </div>
            </div>
            
          </div>
        </div>

        <div className="product-area pt-100">
      <div className="container">
        <div className="section-title text-center">
          <h2>ARTWORKS By {`${artistDetail.first_name} ${artistDetail.last_name}`}</h2>
          <p></p>
        </div>
        <div className="product-tab-list pt-30 pb-55 text-center nav nav-pills" role="tablist">
          <div className="row">
            
          {products.length > 0 && products?.map(product => {
        return (<div className="col-xl-3 col-md-6 col-lg-4 col-sm-6">
              <div className="product-wrap mb-25">
                <div className="product-img">
                 <Link to={process.env.PUBLIC_URL + "/arts/" + product.product_slug}>
                    <img className="default-img" src={product.feature_image} alt={product.product_name} />
                  </Link>
                </div>
                <div className="product-content text-center">
                  <h3>
                     <Link to={process.env.PUBLIC_URL + "/arts/" + product.product_slug}>{product.product_name}</Link></h3>
                  <div className="product-price">
                    <span>﷼ {product.product_price}</span>
                  </div>
                </div>
              </div>
            </div>)})}
         
           
           
          

          </div>
        </div>
      </div>
    </div>


        {/* <div class="related-product-area pb-95">
        <div class="container">
            <div class="section-title text-center mb-50">
                <h2>Related Products</h2>
            </div>
            <div class="swiper-wrap">
                <RelatedProductSlider category=''/>
            </div>
        </div>
        </div> */}
      </div>
    </div>
    </LayoutOne>
    </Fragment>
  );
};

ArtistInfo.propTypes = {
};

export default ArtistInfo;
