import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    exhibitions : [],  // Store the fetched About Us data
    artists : [],
    press_releases : [],
    exhibitionDetails: null,
    artistDetail: null,
    loading: false,     // For loading state
    message : '',
    error: null         // For any errors during the request
  };

  export const getExhibitions = createAsyncThunk(
    'auth/getExhibitions',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/exhibitions');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  
  export const getExhibitionDetails = createAsyncThunk(
    'auth/getExhibitionDetails',
    async (slug, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/exhibitions_details/'+slug);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const applyExhibitions = createAsyncThunk(
    'auth/applyExhibitions',
    async (requestData, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/apply_exhibitions',requestData);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const artistDetails = createAsyncThunk(
    'auth/artistDetails',
    async (slug, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/artist_details/'+slug);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const artist = createAsyncThunk(
    'auth/artist',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/artist_list');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  

  export const pressReleases = createAsyncThunk(
    'auth/pressReleases',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/press_release');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const exhibitionSlice = createSlice({
    name: 'exhibitions',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
            builder
              .addCase(getExhibitions.pending, (state) => {
                state.loading = true;
              })
              .addCase(getExhibitions.fulfilled, (state, action) => {
                state.loading = false;
                state.exhibitions  = action.payload.data.result;
              })
              .addCase(getExhibitions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(getExhibitionDetails.pending, (state) => {
                state.loading = true;
              })
              .addCase(getExhibitionDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.exhibitionDetails  = action.payload.data.result;
              })
              .addCase(getExhibitionDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(applyExhibitions.pending, (state) => {
                state.loading = true;
              })
              .addCase(applyExhibitions.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload.data.message;
              })
              .addCase(applyExhibitions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(artistDetails.pending, (state) => {
                state.loading = true;
              })
              .addCase(artistDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.artistDetail = action.payload.data.result;
              })
              .addCase(artistDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(artist.pending, (state) => {
                state.loading = true;
              })
              .addCase(artist.fulfilled, (state, action) => {
                state.loading = false;
                state.artists = action.payload.data.result;
              })
              .addCase(artist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              })
              .addCase(pressReleases.pending, (state) => {
                state.loading = true;
              })
              .addCase(pressReleases.fulfilled, (state, action) => {
                state.loading = false;
                state.press_releases = action.payload.data.result;
              })
              .addCase(pressReleases.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              });
             
          },
});

export default exhibitionSlice.reducer;
