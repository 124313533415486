import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    aboutUsData: null,  // Store the fetched About Us data
    exhibitionData: null,
    soloExhibitionData: null,
    settings: null,
    artsData: null,
    homeData: null,
    loading: false,     // For loading state
    error: null         // For any errors during the request
  };

  export const postContactForm = createAsyncThunk(
    "general/postContactForm",
    async (formData, { rejectWithValue }) => {
      try {
        // Example API call to post data
        const response = await fetch("https://mattisirvioartgallery.com/admin/api/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          throw new Error("Failed to submit contact form");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  
  

  export const getHomeData = createAsyncThunk(
    'auth/getHomeData',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/home');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getSettingData = createAsyncThunk(
    'auth/settings',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get('https://mattisirvioartgallery.com/admin/api/setting');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

// API call for login
export const getAboutUsPage = createAsyncThunk(
    'auth/getAboutUsPage',
    async (credentials, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/pages/about-us', credentials);
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getExhibitionPage = createAsyncThunk(
    'auth/getExhibitionPage',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/pages/exhibition');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  

  export const getArtsPage = createAsyncThunk(
    'auth/getArtsPage',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/pages/artwork');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  

  export const getSoloExhibitionPage = createAsyncThunk(
    'auth/getSoloExhibitionPage',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://mattisirvioartgallery.com/admin/api/pages/solo-exhibition');
        return response.data; // Assume response contains user data and token
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(getAboutUsPage.pending, (state) => {
            state.loading = true;
          })
          .addCase(getAboutUsPage.fulfilled, (state, action) => {
            state.loading = false;
            state.aboutUsData  = action.payload.data.result;
          })
          .addCase(getAboutUsPage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(getHomeData.pending, (state) => {
            state.loading = true;
          })
          .addCase(getHomeData.fulfilled, (state, action) => {
            state.loading = false;
            state.homeData  = action.payload.data;
          })
          .addCase(getHomeData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(getExhibitionPage.pending, (state) => {
            state.loading = true;
          })
          .addCase(getExhibitionPage.fulfilled, (state, action) => {
            state.loading = false;
            state.exhibitionData  = action.payload.data.result;
          })
          .addCase(getExhibitionPage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(getArtsPage.pending, (state) => {
            state.loading = true;
          })
          .addCase(getArtsPage.fulfilled, (state, action) => {
            state.loading = false;
            state.artsData  = action.payload.data.result;
          })
          .addCase(getArtsPage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(getSoloExhibitionPage.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSoloExhibitionPage.fulfilled, (state, action) => {
            state.loading = false;
            state.soloExhibitionData  = action.payload.data.result;
          })
          .addCase(getSoloExhibitionPage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(getSettingData.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSettingData.fulfilled, (state, action) => {
            state.loading = false;
            state.settings  = action.payload.data.result;
          })
          .addCase(getSettingData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(postContactForm.pending, (state) => {
            state.loading = true;
          })
          .addCase(postContactForm.fulfilled, (state, action) => {
            state.loading = false;
            // Handle the success response
          })
          .addCase(postContactForm.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
      
         
      },
});

export default generalSlice.reducer;
